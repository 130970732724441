import "@gomdigital/sheep-two/dist/styles/App.scss";
import "./App.css";
import {
  Header,
  Hero,
  IconGroup,
  Steps,
  Half,
  Columns,
  Section,
  CardItems,
  FullBlockCta,
  Footer,
} from "@gomdigital/sheep-two";
function App() {
  return (
    <div className="main">
      <div className="relative">
        {/* Header */}
        <Header
          btnText="Open Account"
          btnHref="#"
          logo={
            <img
              alt="logo"
              src={"Genuis-logo.png"}
              className="logo w-[100%] h-[auto]"
            />
          }
          absolute={true}
          modal={false}
        />

        {/* Hero */}
        <Hero
          title="Trade the Smart Way with Genius Markets"
          desc={
            <>
              Forex, Shares, Indices, Gold, Oil & More - Reliable & Powerful
              MT4/MT5 Platforms - Leverage up to 500:1 - Free Education
              Resources - Tight spreads from 0.0 pips with Genius Plus +
            </>
          }
          primaryBtnText="Open Live Account"
          primaryBtnHref="https://geniusmarkets.com/"
          secondaryBtnText="Try Free Demo"
          secondaryBtnHref="https://geniusmarkets.com/"
          heroStyle="bg-cover bg-no-repeat"
        />
      </div>

      {/* Icon Group */}
      <Section title="We Make Trading Simple">
        <div>
          <p className="text-center">
            Thousands of opportunities to trade, tight spreads, transparent
            pricing, powerful platforms. Backed by unmatched support.
          </p>

          <IconGroup
            obj={[
              {
                icon: <img alt="icon" src={"icon1.svg"} />,
                icon_title: "Low Spreads from 0.0 Pips",
              },
              {
                icon: <img alt="icon" src={"icon2.svg"} />,
                icon_title: "Regulated Broker",
              },
              {
                icon: <img alt="icon" src={"icon3.svg"} />,
                icon_title: "Segregated Client Funds",
              },
              {
                icon: <img alt="icon" src={"icon4.svg"} />,
                icon_title: "Fast Execution",
              },
              {
                icon: <img alt="icon" src={"icon5.svg"} />,
                icon_title: "24/5 Client Support",
              },
              {
                icon: <img alt="icon" src={"icon6.svg"} />,
                icon_title: "No Deposit Fees",
              },
            ]}
          />
        </div>
      </Section>

      {/* Steps */}
      <Section>
        <Steps
          title="Joining Us is Easy"
          obj={[
            {
              icon: <img alt="icon" src={"num1.svg"} className="w-9 h-9" />,
              card_title: "Open an account",
              card_content: "Apply for a Genius Markets Live Trading Account",
            },
            {
              icon: <img alt="icon" src={"num2.svg"} className="w-9 h-9" />,
              card_title: "Fund Your Account",
              card_content: "Deposit the minimum funds required.",
            },
            {
              icon: <img alt="icon" src={"num3.svg"} className="w-9 h-9" />,
              card_title: "Start Trading!",
              card_content: "Trade Gold, and 1000+ other instruments.",
            },
          ]}
          bg="dark"
          last_card_title="Start Today"
          last_card_content="Try a free demo"
          last_cardSecondaryBtnHref="https://geniusmarkets.com/"
          last_cardSecondaryBtnText="Open Live Account"
        />
      </Section>

      {/* 6 col */}
      <Section title="Access a Wide Range of Markets">
        <Columns col={3}>
          <CardItems
            obj={[
              {
                icon: <img alt="logo" src={"fx.svg"} className="w-12 h-12" />,
                card_title: "Forex",
                card_content:
                  "Trade wide range of currency pairs with a leading global CFD provider",
              },
              {
                icon: (
                  <img alt="logo" src={"candle.svg"} className="w-12 h-12" />
                ),
                card_title: "Share CFDs",
                card_content:
                  "Trade long or short on share CFDs on the Australian, US, Hong Kong, UK and German exchanges",
              },
              {
                icon: (
                  <img alt="logo" src={"chart.svg"} className="w-12 h-12" />
                ),
                card_title: "Indices",
                card_content:
                  "Access a wide range of Index CFD trades via MT4 & MT5 Platforms",
              },
              {
                icon: (
                  <img alt="logo" src={"metals.svg"} className="w-12 h-12" />
                ),
                card_title: "Metals",
                card_content:
                  "Access a range of precious metal pairs through Genius Markets",
              },
              {
                icon: <img alt="logo" src={"oil.svg"} className="w-12 h-12" />,
                card_title: "Commodities",
                card_content:
                  "Trade one of the world’s most heavily-traded markets",
              },
              {
                icon: (
                  <img alt="logo" src={"upchart.svg"} className="w-12 h-12" />
                ),
                card_title: "Treasuries",
                card_content:
                  "Trade the most liquid bonds futures contract across the US, Europe, UK and Japan",
              },
            ]}
            bg="dark"
          />
        </Columns>
      </Section>

      {/* Combo */}
      <Section title="">
        <div>
          <Half
            title="Powerful Platforms & Tools"
            content={
              <>
                Multiple platforms to support how you trade. Our expertise helps
                you
              </>
            }
            image={
              <img
                alt="hero"
                src={"platform-img.png"}
                className="inset-0 platform-img"
              />
            }
            imageStyle={""}
            primaryBtnHref="https://geniusmarkets.com/"
            primaryBtnText="Open Live Account"
            equalCols={false}
          />
          <div className="mt-10 flex justify-center">
            {/* 3 col */}
            <Columns col={3}>
              <CardItems
                obj={[
                  {
                    icon: (
                      <img alt="logo" src={"mt4.svg"} className="w-12 h-12" />
                    ),
                    card_title: "Metatrader 4",
                    card_content:
                      "The world's most popular trading platform for your phone, tablet or desktop",
                  },
                  {
                    icon: (
                      <img alt="logo" src={"mt5.svg"} className="w-12 h-12" />
                    ),
                    card_title: "Metatrader 5",
                    card_content:
                      "A more powerful release of MetaTrader with greater functionality.",
                  },
                  {
                    icon: (
                      <img
                        alt="logo"
                        src={"add_chart.svg"}
                        className="w-12 h-12"
                      />
                    ),
                    card_title: "Trading Tools",
                    card_content:
                      "We have a range of trading tools to help you manage your trading risk, improve your trading strategies and stay in the know.",
                  },
                ]}
                bg="light"
              />
            </Columns>
          </div>
        </div>
      </Section>

      {/* Full width cta */}
      <Section title="Trade Better with Genius Markets">
        <FullBlockCta
          primaryBtnHref="https://geniusmarkets.com/"
          primaryBtnText="Open Live Account"
          desc=" Forex, Shares, Indices, Gold, Oil & More - Reliable & Powerful MT4/MT5 Platforms - Leverage up to 500:1 - Free Education Resources - Tight Spreads from 0.0 pips with Genius Plus+"
        />
      </Section>

      {/* Footer */}
      <Footer
        logo={
          <div className="flex gap-6 h-max">
            <div>
              <img
                alt="logo"
                src="Genuis-logo.png"
                className="logo-small w-full h-auto"
              />
            </div>
            <div>
              <a
                href="https://mwaliregistrar.com/list_of_entities"
                target="_blank"
                rel="noreferrer"
                className="no-underline"
                style={{ display: "inline-block" }}
              >
                <img
                  src="https://dm1otjfdmqzvu.cloudfront.net/mwaliregistrar.svg"
                  alt="mwaliregistrar.com Logo"
                  style={{
                    backgroundColor: "white",
                    width: "60px",
                  }}
                />
              </a>
            </div>
          </div>
        }
        ftrInfo={[{}, {}]}
        ftrInfo2={[
          {
            content: [],
          },
        ]}
        textBottom={
          <>
            All contents @ 2024 Genius Markets Ltd. All rights reserved.
            <br />
            <br /> Genius Markets Ltd is incorporated in the Union of the
            Comoros with Registered Company Number HY00623408, and is licensed
            and regulated by Mwali International Services Authority (Brokerage
            License Number: T2023315) . Office address: P.B. 1257 Bonovo Road,
            Fomboni, Comoros, KM. <br />
            <br />
            The information on this website is general in nature only and does
            not take into account your personal objectives, financial situation
            or needs. Before acting on the information on this website, you
            should consider whether the information is suitable for you and your
            personal circumstances and if necessary, seek appropriate
            professional advice. <br />
            <br />
            All opinions, conclusions, forecasts or recommendations are
            reasonably held at the time of compilation but are subject to change
            without notice. Trading in CFDs carries significant risks and is not
            suitable for everyone. You do not own or have any interest in the
            rights in the underlying assets.
          </>
        }
      />
    </div>
  );
}

export default App;
